﻿.textblock {
    background-color: $blue-complement;
    margin-bottom: 4.8rem;
    padding: 1.5rem;
    border-radius: 1rem;

    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
    }

    .textblock-link {
        font-size: $font-size-small;
        color: $primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:after {
            border-style: solid;
            border-width: 1.5px 1.5px 0 0;
            content: " ";
            display: inline-block;
            height: 0.45rem;
            left: 0.5rem;
            position: relative;
            transform: rotate(45deg);
            width: 0.45rem;
        }
    }
}
