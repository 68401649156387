.header {
	@include set-typography('body', 'header');
	color: $black-70;
	border-bottom: 1px solid $black-30;

	.navbar {
		height: 9rem;
		background-color: $white;

		&--thin {
			height: 6rem;
		}
	}

	.img-ad {
		padding: 2rem 0;
		cursor: pointer;

		@include media-breakpoint-down(md) {
			padding: 1rem 0;
		}
	}

	&__logo {
		padding-right: 2rem;
	}

	&__login {
		border: none;
		box-shadow: 15px 12px 16px 11px rgb(16 24 40 / 10%),
			0px 4px 6px -2px rgb(16 24 40 / 5%);
		border-radius: $border-radius;
		padding: $spacer-4;
		min-width: 30rem;

		.popover-arrow {
			display: none;
		}
	}

    &__search {
        min-width: 21.875rem;
        @include media-breakpoint-down(md) {
			min-width: 15rem;
		}
    }
}

.skip-content {
	background-color: $black-20;
}
