.card {
	border-radius: $border-radius;
	box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
		0px 1px 2px rgba(16, 24, 40, 0.06);
	border: 0;
	position: relative;
	/*height: 100%;*/
	padding: $spacer-4;

	&.nopadding {
		padding: 0;
	}

	.card-header {
		background: none;
	}

	.card-body {
		padding: 0;

		&.horizontal-scroll {
			overflow-x: auto;

			& > * {
				width: calc($container-max-width - var(--bs-gutter-x) - 2px);
				padding: 0;
			}
		}
	}

	&-linked {
		@include media-breakpoint-up(md) {
			padding-bottom: $spacer-8;
		}
	}

	&-link {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		padding: $spacer-4;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include media-breakpoint-up(md) {
			align-items: flex-end;
		}
	}

	.card-footer {
		background: none;
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
		padding: 1rem;
		border-top: 1px solid $black-35;
	}

	.text {
		padding: $spacer-2 0;
		&--grey {
			color: $black-70;
		}
	}

	&__image {
		&--rounded {
			border-radius: $border-radius;
		}
		&--limit-height {
			max-height: 10rem;
		}
	}

	&__list {
		margin-top: $spacer-2;
		padding-top: $spacer-2;
		border-top: 1px solid $black-40;
		ul {
			padding-left: 0;
			list-style-type: none;
			margin: 0;
		}
	}

	.chevron-button {
		margin-left: auto;
		border-radius: 50%;
		background-clip: padding-box;
		background-color: $white;
		font-size: 1.3rem !important;
		vertical-align: middle;
		width: 3rem;
		line-height: 3rem;
		box-sizing: border-box;
		text-align: center;
		color: #0d171a;
	}

	&--pink {
		background-color: $pink-complement;
		border: 0;
		.chevron-button {
			color: $white;
			background-color: $red-secondary;
		}
	}

	&--mint {
		background-color: $mint-complement;
		border: 0;
		.chevron-button {
			color: $white;
			background-color: $green-secondary;
		}
	}

	&--yellow {
		background-color: $yellow-complement;
		border: 0;
		.chevron-button {
			color: $white;
			background-color: $yellow-secondary;
		}
	}
}

.cardSection {
	@extend .card;
	height: auto !important;
}