.collapse {
    &--full {
        &:not(.show) {
            display: none!important;
        }
    }
    &--partial {
        min-height: 12.8rem;
        @include media-breakpoint-down(sm) {
            min-height: 8.5rem;
        }
        &:not(.show) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 9;
            -webkit-box-orient: vertical;

            
            @include media-breakpoint-down(sm) {
                -webkit-line-clamp: 6;
            }
        }
    }

    &--partial--large {
        min-height: 26.5rem;
        @include media-breakpoint-down(sm) {
            min-height: 8.5rem;
        }
        &:not(.show) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 19;
            -webkit-box-orient: vertical;

            @include media-breakpoint-down(sm) {
                -webkit-line-clamp: 6;
            }
        }
    }

    &__read {
        text-decoration: none;
        padding-top: $spacer-3;
        text-align: center;

        &[aria-expanded="false"]{
            .collapse__read__more {
                display: inline-block;
            }
            .collapse__read__less {
                display: none;
            }
            &::after {
                position: absolute;
                font-family: $bootstrap-icons-font;
                content: "\F282";
                border: none;
                margin-left: $spacer-2;
            }
        }

        &[aria-expanded="true"]{
            .collapse__read__more {
                display: none;
            }
            .collapse__read__less {
                display: inline-block;
            }
            &::after {
                position: absolute;
                font-family: $bootstrap-icons-font;
                content: "\F286";
                border: none;
                margin-left: $spacer-2;
            }
        }

    }

}