// Primary color
$primary: #4075C8;

// Secondary colors
$red-secondary: #EC627A;
$green-secondary: #229490;
$yellow-secondary: #F5B067;

// complementary colors
$pink-complement: #FFF2FD;
$mint-complement: #E4F8F7;
$yellow-complement: #FFF2E5;
$red-complement: #D93552;
$green-complement: #1E827E;
$blue-complement: #F5FAFF;

// notifications
$red-error: #D92D20;
$red-error-accent: #FFFBFA;
$green-success: #027A48;
$green-success-accent: #F6FEF9;

// Black and white
$black: #000000;
$black-90: #101828;
$black-85: #1D2939;
$black-80: #344054;
$black-75: #475467;
$black-70: #667085;
$black-50: #98A2B3;
$black-40: #D0D5DD;
$black-35: #E4E7EC;
$black-30: #F2F4F7;
$black-20: #F9FAFB;
$black-15: #FCFCFD;
$white: #ffffff;

$black-20-opacity: #f9fafbb5;

//error volor
$red-error-border-color: #dc3545;

// Blues
$blue-50: #EFF8FF;
$blue-light-50: #F0F9FF;

// Greens
$green-50: #d0ddd0;

// link color
$link-color: $primary;
$link-color-visited: #42307D;

// fluid-container max width
$container-max-width-lg: 2160px;
//normal container max width
$container-max-width: 1280px;
$container-max-width-xs: 540px;

//border radius
$border-radius: 0.5rem;

// Spacers
$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);
$spacer-6: map-get($spacers, 6);
$spacer-7: map-get($spacers, 7);
$spacer-8: map-get($spacers, 8);
$spacer-9: map-get($spacers, 9);

// Fonts
$font-size-base: 16px;
$font-size-small: 0.875rem;
$font-size-tiny: 0.75rem;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-extra-large: 3rem;

$font-family: Inter, Sans-Serif !important;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;

$font-styles: (
    'headings': (
      'h1--big': (
        font-size: 3.75rem,
        line-height: 1.2,
        font-family: $font-family,
        font-weight: $font-weight-semibold,
        'sm': (
          font-size: 3rem
        )
      ),
      'h1': (
        font-size: $font-size-extra-large,
        line-height: 1.25,
        font-family: $font-family,
        font-weight: $font-weight-semibold,
        'sm': (
          font-size: 2.75rem
        )
      ),
      'h2': (
        font-size: $font-size-large,
        line-height: 1.375,
        font-family: $font-family,
        font-weight: $font-weight-semibold,
        'sm': (
          font-size: 1.75rem
        )
      ),
      'h3': (
        font-size: $font-size-medium,
        line-height: 1.333333,
        font-family: $font-family,
        font-weight: $font-weight-semibold,
        'sm': (
          font-size: 1.5rem
        )
      ),
      'h4': (
        font-size: 1.25rem,
        line-height: 1.5,
        font-family: $font-family,
        font-weight: $font-weight-semibold,
        'sm': (
          font-size: 1.25rem
        )
      ),
    ),
    'body': (
      'ingress': (
        font-size: 1.25rem,
        line-height: 1.5,
        font-family: $font-family,
        font-weight: $font-weight-regular
      ),
      'regular': (
        font-size: $font-size-base,
        line-height: 1.5,
        font-family: $font-family,
        font-weight: $font-weight-regular
      ),
      'small': (
        font-size: $font-size-small,
        font-family: $font-family,
        font-weight: $font-weight-medium
      ),
      'small-light': (
        font-size: $font-size-small,
        font-family: $font-family,
        font-weight: 300
      ),
      'small-medium': (
        font-size: $font-size-small,
        font-family: $font-family,
        font-weight: $font-weight-medium
      ),
      'tiny': (
        font-size: $font-size-tiny,
        font-family: $font-family,
        font-weight: $font-weight-regular
      ),
      'semibold': (
        font-size: 1rem,
        font-family: $font-family,
        font-weight: $font-weight-semibold
    )
),
'forms': (
      'input': (
        font-size: 0.6875rem,
        font-family: $font-family,
        font-weight: $font-weight-regular
      ),
      'option': (
        font-size: 0.8125rem,
        font-weight: $font-weight-medium,
        font-family: $font-family
      ),
      'label': (
        font-size: 0.875rem,
        font-weight: $font-weight-medium,
        font-family: $font-family
      )
    )
);