.breadcrumbs {
    margin: $spacer-4 0;

    &__breadcrumb {
        padding: 0 $spacer-2;
        color: $black-40;
        font-size: 1.25rem;
    }

    a {
        text-decoration: none;
        &:visited {
            color: $link-color;
        }

        &:hover {
            text-decoration: underline;
        }

        &:first-child {
            &:hover {
                text-decoration: none;
            }
        }
    }
}