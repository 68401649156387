$class-name: '.HeroBlock';

#{$class-name} {
    position: relative;
    background-color: $blue-complement;

    &--imageRight {
        #{$class-name}-row {
            @include media-breakpoint-up(md) {
                justify-content: flex-start;
            }
        }

        #{$class-name}-imgWrap {
            @include media-breakpoint-up(md) {
                right: 0;
                left: auto;
            }
        }
    }

    &-row {
        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }

    &-col {
        display: flex;

        @include media-breakpoint-up(md) {
            min-height: 20rem;
        }

        @include media-breakpoint-up(lg) {
            min-height: 25rem;
        }
    }

    &-imgWrap {
        position: relative;
        min-height: 16rem;

        @include media-breakpoint-up(md) {
            height: 100%;
            width: calc(50% - 15px);
            position: absolute;
            left: 0;
            min-height: 0;
        }
    }

    &-img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: "object-fit: cover";
    }

    &-textWrap {
        padding: $spacer-3 0;

        @include media-breakpoint-up(lg) {
            padding: $spacer-5 0;
            align-self: center;
        }
        @include set-typography('body', 'ingress');
        color: $black-70;
    }

    &-header {
        color: $black-90;
        margin-bottom: $spacer-3;
    }

    &-cta {
        margin-top: $spacer-4;
        display: block;
        width: 100%;
        text-decoration: none;

        &::after {
            position: absolute;
            font-family: $bootstrap-icons-font;
            content: "\F285";
            border: none;
            font-size: 1rem;
            margin-left: $spacer-2;
            line-height: 1.625rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1rem;
            margin-top: $spacer-5;
            display: inline-block;
            width: auto;
        }
    }
}