$class-name: '.PersonalizedBlock';

#{$class-name} {
    position: relative;
    min-height: 10rem;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: contain;

    .btn {
        margin-top: $spacer-3;
        margin-right: $spacer-3;
    }

    &.Roleclass--pink {
        background-image: url('../../images/orbs-pink-mobile.svg');
        background-color: $pink-complement;

        @include media-breakpoint-up(md) {
            background-image: url('../../images/orbs-pink.svg');            
        }

        .btn {
            @extend .btn-red;
        }
    }

    &.Roleclass--mint {
        background-image: url('../../images/orbs-mint-mobile.svg');
        background-color: $mint-complement;

        @include media-breakpoint-up(md) {
            background-image: url('../../images/orbs-mint.svg');
        }

        .btn {
            @extend .btn-green;
        }
    }

    &.Roleclass--yellow {
        background-image: url('../../images/orbs-yellow-mobile.svg');
        background-color: $yellow-complement;

        @include media-breakpoint-up(md) {
            background-image: url('../../images/orbs-yellow.svg');
        }

        .btn {
            @extend .btn-yellow;
        }
    }
}