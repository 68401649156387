.loader {
	z-index: 10000;
	position: fixed;
	transition: 200ms ease opacity;
	pointer-events: none;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.4);
	top: 0;

	&__spinner {
		height: 4rem;
		width: 4rem;
	}
}
