$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 5,
  9: $spacer * 6,
) !default;
$form-check-input-checked-color: #0094ff;
$form-check-input-checked-border-color: #0094ff;
$form-check-input-checked-bg-color: #ffffff;


$utilities: (
  "width": (
    property: width,
    class: w,
    values: (
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  )
 );