.cart {
	.form-control {
		border-radius: $border-radius;
	}

	h2 {
		font-size: 1.125rem;
		font-weight: 500;
	}
}

@media screen and (max-width: 575px) {
	.cart {
		.cart-functions {
			& > div {
				width: 100%;

				.btn {
					flex-grow: 1;
				}
			}
		}
	}
}

.pulse {
	overflow: visible;
	position: relative;
}
.pulse:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: inherit;
	border-radius: inherit;
	transition: opacity 1s, transform 1s;
	animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
	z-index: -1;
}
@keyframes pulse-animation {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	50% {
		opacity: 0;
		transform: scale(3);
	}
	100% {
		opacity: 0;
		transform: scale(3);
	}
}
