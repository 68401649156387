.footer {
    background-color: $black-85;
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    li {
        color: $black-35;
        a {
            text-decoration: none;
            color: $black-35;
        }
    }

    .category {
        @include set-typography('body', 'small');
        color: $black-50;
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: block;
    }

    &__detail {
        border-top: 1px solid $black-75;
        display: flex;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: 2rem;

        &__copy-right {
            margin-left: auto;
            color: $black-50;
        }
    }
}

@include media-breakpoint-down(md) {
    .footer {
        .category {
            margin-top: 0;
        }
        .list-unstyled {
            margin-bottom: 0;
        }

        &__detail {
            flex-wrap: wrap;
            &__copy-right {
                padding-top: 1rem;
                display: block;
                width: 100%;
            }
        }
    }
}