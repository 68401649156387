.submenu {
	.nav {
		border-bottom: 1px solid $black-40;
		gap: 1rem;

		.nav-link {
			color: $black-70;
			margin-bottom: -1px;

			&.active {
				color: $primary;
				border-bottom: 2px solid $primary;
			}
		}
	}
}
