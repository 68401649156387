.pagination {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.link-pill {
		border-radius: $border-radius;
		height: 2.5rem;
		gap: 0.5rem;
	}

	.page-pills {
		gap: 0.5rem;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 2.5rem;
			width: 2.5rem;
			border-radius: $border-radius;
		}
	}
}
