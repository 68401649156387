.navbar {
    @include set-typography('body', 'regular');
    font-weight: $font-weight-medium;
    .megaMenu {
        background-color: $white;
        z-index: 100;
        .dropdown-menu {
            border-radius: 0;
            font-size: 1.125rem;
            &.show {
                border: none;
                left:0; 
                right:0; 
                width:100%; 
                margin-top:0;
                top: 50%;

                .col-megaMenu {

                    &--grey {
                        background-color: $black-20;
                    }

                    .list-unstyled {
                        margin-top: 0;
                        margin-bottom: 0;
                        border-right: 1px solid $black-30;
                        
                        .list-item {
                            margin-bottom: $spacer-2;
                            position: relative;

                            a {
                                padding: $spacer-2 2.5rem $spacer-2 $spacer-3;
                                text-decoration: none;
                                color: $black-80;
                                display: block;

                                &:hover {
                                    background-color: $blue-light-50
                                }
                                &:focus-visible {
                                    background-color: $blue-light-50
                                }
                                &:active {
                                    background-color: $blue-light-50
                                }

                                &::after {
                                    position: absolute;
                                    font-family: $bootstrap-icons-font;
                                    color: $black-50;
                                    content: "\F285";
                                    border: none;
                                    right: $spacer-3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .menu {
        .nav-link {
            color: $black-70;
            padding: 0.5rem 1rem;
        }

        .dropdown-toggle {
            padding-right: 2rem;
            &::after {
                position: absolute;
                font-family: $bootstrap-icons-font;
                content: "\F282";
                border: none;
            }
        }

        .show {
            .dropdown-toggle {
                padding-right: 2rem;
                &::after {
                    position: absolute;
                    font-family: $bootstrap-icons-font;
                    content: "\F286";
                    border: none;
                }
            }
        }
    }

    .navbar-collapse {
        z-index: 1000;
    }
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .navbar {        
        .megaMenu {
            position:static!important;
            .dropdown-menu {
                border-radius: 0;
                &.show {
                    border-top: 1px solid $black-20;
                    left:0; 
                    right:0; 
                    width:100%; 
                    margin-top:0;
                    max-width: 1256px;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0;
                    border-radius: $border-radius;

                    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
                    filter: drop-shadow(0px -4px 24px rgba(16, 24, 40, 0.06));

                    .col-megaMenu {
                        height: 100%;
                        padding: $spacer-5 0 $spacer-3;
                                     
                        &--grey {
                            position: relative;
                            border-top-left-radius: $border-radius;
                            border-bottom-left-radius: $border-radius;
                            background-color: $black-20;
                            padding: $spacer-5 $spacer-3 $spacer-3;

                            .list-unstyled .list-item {
                                margin-right: 0;
                            }
                        }
                        &--no-border {
                            .list-unstyled {
                                border-right: none;
                            }
                        }
                        
                        .list-unstyled {
                            padding-bottom: $spacer-3;

                            .list-item {
                                margin-right: $grid-gutter-width;
                            }
                        }
                    }
                }
            }
        }
    }
	
}	
/* ============ desktop view end ============ */


/* ============ mobile view ============ */
@media(max-width: 991px){
    .menu {
        background-color: $white;
        width: 100%;
        position: absolute;
        z-index: inherit;
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }

    .navbar {
        .navbar-collapse {
            margin: $spacer-3 0;
        }
        .mobile-icon-item {
            font-size: 2rem;
            color: $black-70;
        }
    }
}
/* ============ mobile view end ============ */