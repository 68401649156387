.search {
    &__box {
        border: 1px solid $black-40;
        padding: $spacer-3;

        &__complex {
            margin: -$spacer-3;
            margin-top: $spacer-3;
            padding: $spacer-3;
            border-top: 1px solid $black-40;
        }
    }

    &__filter{
        @include set-typography('body', 'small-medium');
        border: 1px solid $black-40;
        padding: $spacer-3;

        &__group {
            border-bottom: 1px solid $black-40;
            padding-top: $spacer-3;
            padding-bottom: $spacer-3;

            &--no-border {
                border: none;
            }

            &__link {
                @include set-typography('body', 'tiny');
                color: $black-70;
                text-decoration: none;
                float: right;
                cursor: pointer;

                &:hover {
                    color: $black-90;
                }
            }

            &__list {
                padding-top: $spacer-3;

                &__desc {
                    max-width: 90%;
                    cursor: pointer;

                    label {
                        display: flex;
                    }
                }

                &__count {
                    @include set-typography('body', 'tiny');
                    border-radius: 50%;
                    background-color: $blue-50;
                    color: $primary;
                    width: 1.5rem;
                    height: 1.5rem;
                    text-align: center;
                    line-height: 1.5rem;
                    margin-top: -3px;
                }
            }
        }
    }

    &__result{
        &__header {
            margin-top: $spacer-4;
            display: flex;
        }

        &__heading {
            padding-right: $spacer-4;
        }

        &__order {
            margin-left: auto;
        }

        &__item{
            margin-top: $spacer-3;
            border: 1px solid $black-40;
            background-color: $white;
            border-radius: $border-radius;

            &--reviewed {
                background-color: $green-50;
            }

            &__image {
                max-height: 17rem;
                @include media-breakpoint-up(md) {
                    max-height: 12.5rem;
                }
            }

            .author {
                @include set-typography('body', 'small-light');    
            }

            .title {
                text-decoration: none;
                color: $black-90;
            }

            .description {
                vertical-align: top;
                width: 40%;
                display: inline-block;
                font-weight: 600;
            }

            .value {
                width: 60%;
                display: inline-block;
            }

            &__footer {
                border-top: 1px solid $black-40;
            }
        }
    }
}