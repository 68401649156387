.inner-icon {
    position: relative;
    display: inline;
    &__icon {
        position: absolute;
        padding: 0.65rem;
        pointer-events: none;
        font-size: 1.1rem;
        color: $black-70;
        z-index: 10;
    }

    &--left {
        
        &__input {
            padding-left: 2.25rem !important;
        }
        &__icon {
            left: 0px;
        }
    }
    &--right {
        &__input {
            padding-right: 2.25rem !important;
        }
        &__icon {
            right: 0px;
        }
    }
}