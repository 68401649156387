.Block {
    padding: $spacer-7 0;

    @include media-breakpoint-up(md) {
      padding: $spacer-9 0;
		}

    &--sm {
      padding: $spacer-4 0;

      @include media-breakpoint-up(md) {
        padding: $spacer-6 0;
      }
    }

    &-border {
      border-bottom: 1px solid $black-35;
    }

    &-md-2-5 {
      @include media-breakpoint-up(md) {
        flex: 0 0 auto;
        width: 20%;
      }
    }
}