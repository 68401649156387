﻿.nav-tabs {
    width: fit-content;
    border: 1px solid #f2f4f7;
    border-radius: 0.5rem;
    background: $black-20;
    padding: 0.25rem;
    gap: 0.5rem;

    .nav-item {
        .nav-link {
            border-radius: 0.5rem;
            color: $black-70;
            cursor: pointer;
            border: 0;

            &.active {
                border-radius: 0.5rem;
                background: $white;
                font-family: $font-family;
                font-weight: $font-weight-medium;
                color: $black-80;
                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
            }
        }

        &.active {
            background-color: $blue-light-50;
            border: 0;
            border-bottom: 2px solid $primary;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            color: $primary;
        }
    }

    &--primary {
        background: none;
        border-width: 0 0 1px 0;
        border-radius: 0;
        width: auto;
        padding: 0;
        gap: 0;

        .nav-item {
            .nav-link {
                border-radius: 0;
                margin-bottom: -1px;
                color: $black-70;
                border: 0;

                &.active {
                    border-radius: 0;
                    background: $white;
                    font-family: $font-family;
                    font-weight: $font-weight-medium;
                    color: $primary;
                    box-shadow: none;
                    border-bottom: 2px solid $primary;
                    background: $blue-light-50;
                }
            }

            &.active {
                background-color: $blue-light-50;
                border: 0;
                border-bottom: 2px solid $primary;
                font-family: $font-family;
                font-weight: $font-weight-medium;
                color: $primary;
            }
        }
    }
}

.tab-content {
    table,
    .table {
        border-bottom-width: 0px;
        box-shadow: none;
    }

    .table > :not(caption) > * > * {
        border-bottom-width: 0px;
    }

    .table {
        caption-side: top;

        caption {
            padding: 0;
            border-bottom: 2px solid $black-30;

            &.semibold {
                font-weight: $font-weight-semibold;
            }

            ~ *:last-child {
                border-bottom: 2rem solid #FFF;
            }
        }

        > :not(:first-child):not(caption + tbody) {
            border-top: 2rem solid #FFF;
        }
    }

    .no-border-top .table > :not(:first-child) {
        border-top: 0; /* Remove border-top this specific table */
    }
}
