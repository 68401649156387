.upload-area {
    height: 16rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;

    &__input {
        display: none;
    }
    &__label {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: $border-radius;
        border-style: dashed;
        border-color: $black-40;
        background-color: $black-20;

        &--drag-active {
            background-color: #ffffff;
        }

        &__image {
            position: absolute;
            border-radius: 0.5rem;
            opacity: 0.7;
            height: 98%;
            width: 98%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__text {
        display: inline-block;
        color: $black-70;
        &__link {
            cursor: pointer;
            color: $primary;
            display: inline-block;
        }
    }

    &__button {
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        background-color: transparent;
      }

    &__element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}