.btn {
    padding: 0.625rem 1.25rem;
    border-radius: $border-radius;

    &:focus-visible {
        box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
    }

    &:visited {
        color: $white;
    }

    &-primary {
        @include button-variant( $primary, $primary, $white, #2f5a9d, #b3cef9, $white );

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
        }
    }

    &-green {
        @include button-variant( $green-complement, $green-complement, $white, darken($green-complement, 10%), lighten($green-complement, 50%), $white );

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
        }
    }

    &-red {
        @include button-variant( $red-complement, $red-complement, $white, darken($red-complement, 10%), lighten($red-complement, 50%), $white );

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
        }
    }

    &-yellow {
        @include button-variant( $yellow-secondary, $yellow-secondary, $white, darken($yellow-secondary, 10%), lighten($yellow-secondary, 50%), $white );

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
        }
    }

    &-secondary {
        @include button-variant( $white, $black-40, $black-80, $black-20, $black-40, $black-80, $white, #b3cef9, $black-80, $white, $black-35, $black-40 );

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem rgba(179, 206, 249, 1);
        }

        &:visited {
            color: $black-80;
        }
    }

    &-link {
        @include button-variant( transparent, transparent, $link-color, transparent, transparent, $link-color, $white, $black, $black-80, $white, $black-35, $link-color );

        &-small {
            font-size: .9rem;
        }

        padding: 0;
        vertical-align: baseline;
        text-align: left;

        &:focus-visible {
            box-shadow: 0 0 0 0.25rem $black;
        }

        &:visited {
            color: $black-80;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &-sm {
        padding: 0.5rem 0.875rem;
    }

    .bi {
        font-size: 1.3rem;
        vertical-align: -0.1rem;
        line-height: 1.25rem;
    }
}

// button-variant variables :
// $background,
// $border,
// $color: color-contrast($background),
// $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
// $hover-border: if($color == $color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
// $hover-color: color-contrast($hover-background),
// $active-background: if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
// $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
// $active-color: color-contrast($active-background),
// $disabled-background: $background,
// $disabled-border: $border,
// $disabled-color: color-contrast($disabled-background)
