.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
    display: block;
    font-weight: normal;
    margin: 0;
    margin-bottom: $spacer-2;
    line-height: 1.4;
    letter-spacing: normal;
}

h1,
.h1 {
    @include set-typography('headings', 'h1');
    margin-bottom: $spacer;
}

.h1--big {
    @include set-typography('headings', 'h1--big');
    margin-bottom: $spacer;
}

h2,
.h2 {
    @include set-typography('headings', 'h2');
}

h3,
.h3 {
    @include set-typography('headings', 'h3');
}

h4,
.h4 {
    @include set-typography('headings', 'h4');
}

p {
    font-size: $font-size-base;
    margin-bottom: 0;
}

.preamble {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.875rem;
    color: $black-70;
}

.preamble-border-bottom {
    display: block;
    border-color: $black-35;
    border-bottom-width: thin;
    border-bottom-style: solid;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
}

.text {
    &-primary {
        color: $primary !important;
    }
}

.saga {
    p {
        margin: 0 0 1rem 0;
    }
}
