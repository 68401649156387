.page {
	height: 100%;
	padding-bottom: $spacer-7;

	&--grey {
		background-color: $black-15;
	}

	.print-version {
		display: none;
	}
}

@media print {
	header,
	footer,
	ul#epi-quickNavigator {
		display: none;
	}

	.page {
		background: none;

		&--custom-print {
			.breadcrumbs,
			.nav,
			h1,
			#app-content > div:not(.print-version) {
				display: none;
			}

			.print-version {
				display: block;
				font-size: 11pt;
				word-break: break-all;
			}
		}
	}
}
