@mixin set-typography($typeface-name: 'body', $style-name: 'regular') {
    $this-typeface: map-get($font-styles, $typeface-name);
    $this-style: map-get($this-typeface, $style-name);
  
    @each $property, $value in $this-style {
      @if map-has-key($grid-breakpoints, $property) and type-of($value) == 'map' {
        @media (max-width: map-get($grid-breakpoints, $property)) {
          @each $subproperty, $subvalue in $value {
            #{$subproperty}: $subvalue;
          }
        }
      } @else {
        #{$property}: $value;
      }
    }
  }

  /*
Browser support
*/
@mixin browser-edge {
    @supports (-ms-user-select: none) {
      @content;
    }
  }

@function calculateRem($size) {
  $remSize: calc(#{$size} / #{$font-size-base});
  @return #{$remSize}rem;
}

@mixin font-size-to-rem($size) {
    font-size: calculateRem($size);
}