﻿.videoblock {
    video {
        width: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    iframe {
        width: 100%;
        aspect-ratio: 1.5;
        border-radius: 10px;
    }
}
