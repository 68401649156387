$utilities-prefix: '.u-';

//*************Backgrounds********************//
#{$utilities-prefix}background-white {
	background-color: $white;
}
#{$utilities-prefix}background-primary {
	background-color: $primary;
}
#{$utilities-prefix}background-black {
	background-color: $black;
}
#{$utilities-prefix}background-grey {
	background-color: $black-20;
}
#{$utilities-prefix}background-grey-low-opacity {
	background-color: $black-20-opacity;
}
#{$utilities-prefix}background-blue {
	background-color: $blue-50;
}
#{$utilities-prefix}background-green {
	background-color: $green-secondary;
}

#{$utilities-prefix}background-red {
	background-color: $red-secondary;
}

#{$utilities-prefix}background-yellow {
	background-color: $yellow-secondary;
}
//*************Backgrounds********************//
//*************Text********************//
#{$utilities-prefix}text-black {
	color: $black-90;
}
#{$utilities-prefix}text-white {
	color: $white;
}

#{$utilities-prefix}text-grey {
	color: $black-70 !important;
}

#{$utilities-prefix}text-grey-dark {
	color: $black-80;
}

#{$utilities-prefix}text-grey-light {
	color: $black-50;
}

#{$utilities-prefix}text-error {
	color: $red-error;
}

#{$utilities-prefix}text-primary {
	color: $primary;
}
#{$utilities-prefix}text-red {
	color: $red-secondary;
}
#{$utilities-prefix}text-weight-medium {
	font-weight: $font-weight-medium;
}
#{$utilities-prefix}text-small-medium {
	@include set-typography('body', 'small-medium');
}
#{$utilities-prefix}text-semibold {
	@include set-typography('body', 'semibold');
}
#{$utilities-prefix}text-tiny {
	@include set-typography('body', 'tiny');
}
#{$utilities-prefix}text-decoration-none {
	text-decoration: none;
}
#{$utilities-prefix}text-monospace-pre {
	font-family: monospace;
	white-space: pre;
}
#{$utilities-prefix}text-include-linebreak {
	white-space: pre-line;
}
//*************Text********************//
//*************Other********************//
#{$utilities-prefix}border-radius {
	border-radius: $border-radius;
}

#{$utilities-prefix}blurred {
	filter: blur(0.1rem);
}

#{$utilities-prefix}border {
	border-width: 1px;
	border-radius: $border-radius;
	border-color: $black-35;
	border-style: solid;
}

#{$utilities-prefix}border-error {
	border-width: 1px;
	border-radius: $border-radius;
	border-color: $red-error-border-color;
	border-style: solid;
}

#{$utilities-prefix}image-height-md {
	max-height: 21.875rem;
}

#{$utilities-prefix}chevron-after-right {
	margin-right: $spacer-3;
	&::after {
		position: absolute;
		font-family: $bootstrap-icons-font;
		content: '\F285';
		border: none;
		margin-left: $spacer-1;
	}
}

#{$utilities-prefix}cursor-pointer {
	cursor: pointer;
}

#{$utilities-prefix}horizontal-scroll {
	overflow-x: auto;
}

#{$utilities-prefix}vertical-scroll {
	overflow-y: auto;
}
#{$utilities-prefix}dnd-active {
	border: 1px solid $black-50;
	box-shadow: 2px 2px 15px 0px $black-50;
	background: $white;
}
#{$utilities-prefix}dnd-container-active {
	border: 1px solid $black-50;
}
#{$utilities-prefix}dnd-container-inactive {
	border: 1px solid transparent;
}
#{$utilities-prefix}z-index-1 {
	z-index: 1;
}

#{$utilities-prefix}fixed-width-185 {
	width: 11.5625rem;
}

#{$utilities-prefix}max-width-39 {
	max-width: 39rem;
}
//*************Other********************//
