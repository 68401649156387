.notifications {
    position: fixed;
    bottom: 0;
    width: 80%;
    max-width: $container-max-width;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    &__notification {
        @include set-typography('body', 'semibold');
        border-radius: $border-radius;
        align-items: center;
        display: flex;

        &.alert-success {
            color: $green-success;
            border-color: $green-success;
            background-color: $green-success-accent;

            .btn-close {
                $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$green-success}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
                background: transparent escape-svg($btn-close-bg) center/1em auto no-repeat;
                opacity: 1;
            }
        }

        &.alert-warning {
            color: $yellow-secondary;
            border-color: $yellow-secondary;
            background-color: $yellow-complement;

            .btn-close {
                $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$yellow-secondary}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
                background: transparent escape-svg($btn-close-bg) center/1em auto no-repeat;
                opacity: 1;
            }
        }

        &.alert-danger {
            color: $red-error;
            border-color: $red-error;
            background-color: $red-error-accent;

            .btn-close {
                $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$red-error}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
                background: transparent escape-svg($btn-close-bg) center/1em auto no-repeat;
                opacity: 1;
            }
        }

        &.alert-info {
            color: $primary;
            border-color: $primary;
            background-color: $blue-light-50;

            .btn-close {
                $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
                background: transparent escape-svg($btn-close-bg) center/1em auto no-repeat;
                opacity: 1;
            }
        }
        &.notifications__notification > div {
            display: flex;
            align-items: center;
        }

        .btn-close {
            padding: 1.45rem 1rem;
        }
    }
}
