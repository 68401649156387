/* inter */
@font-face {
	font-family: 'Inter';
	font-weight: 100 900;
	font-stretch: 100%;
	font-style: normal;
	src: local(''),
		url('../../fonts/Inter-VariableFont_slnt\,wght.ttf')
			format('woff2-variations'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('../../fonts/Inter-VariableFont_slnt\,wght.ttf')
			format('woff-variations'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Bootstrap-icons*/
.bi {
    font-size: 1.3rem;
    &--unset-height {
        line-height: unset !important;
    }
}
[class^='bi-'],
[class*=' bi-'] {
	&::before {
		display: inline-block;
		font-family: $bootstrap-icons-font !important;
		font-style: normal;
		font-weight: normal !important;
		font-variant: normal;
		text-transform: none;
		// line-height: 1;
		// vertical-align: -0.125em;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&.small {
		&::before {
			font-size: 0.7rem;
			vertical-align: 0;
		}
	}

	&.tiny {
		font-size: 1rem;
		line-height: 1rem;
		vertical-align: text-bottom;
	}
}
