.information-icon {
    input, select {
        &.form-control {
            border-right: none;
        }

        &.is-invalid + .input-group-text {
            background: none;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            border: 1px solid $red-error;
        }
    }

    & ~.invalid-feedback {
        display: block;
    }

    .input-group-text {
        background: none;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}