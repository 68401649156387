.badge {
	&.rounded-pill {
		font-size: 0.875rem;
		font-weight: 500;
		--bs-bg-opacity: 0.1 !important;
	}
	&.filter-pill {
		background-color: $blue-50;
		color: $primary;
	}
}
