.validation {
	&__error {
		&__block {
			background-color: $red-error-accent;
			border: 1px solid $red-error;
			border-radius: $border-radius;
			padding: $spacer-3;
			margin: $spacer-4 0;

			i {
				color: $red-error;
			}

			&__description {
				font-weight: $font-weight-medium;
				font-family: $font-family;
				display: block;
				color: $red-error;
				margin-bottom: $spacer-2;
			}

			&__list {
				color: $red-error;
				padding-left: 2.35rem;
				margin-bottom: $spacer-2;

				li {
					margin-left: $spacer-4;
				}
			}
		}
	}
}

//Example HTML
// <div class="mx-5">
//     <div class="validation__error__block">
//         <span class="validation__error__block__description">Någonting gick helt fel!</span>
//         <ul class="validation__error__block__list">
//             <li>Titel är obligatorisk</li>
//             <li>fel 2</li>
//             <li>fel 3</li>
//             <li>fel 4</li>
//         </ul>
//     </div>
// </div>
