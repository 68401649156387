html {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: grayscale;
	font-size: $font-size-base;
	overflow-x: hidden;
	height: 100%;
}

body {
	font-family: $font-family;
	font-size: $font-size-base !important;
	font-weight: $font-weight-regular;
	line-height: 1.5;
	color: $black-90;
	position: relative;
	min-height: 100%;
	margin: 0;
	padding: 0;
	transition: none !important;
	height: 100%;

	@include browser-edge {
		height: auto !important;
	}
	.container {
		width: 100%;
		max-width: $container-max-width;

		&--xs {
			max-width: $container-max-width-xs;
		}
	}

	.container-fluid {
		max-width: $container-max-width-lg;
	}
}

#app-nav {
	background-color: $black-20;
}

.PageContent {
	margin: 0 auto;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
}

img {
	max-width: 100%;
}

a {
	color: $link-color;
	&:hover {
		color: $link-color;
	}
	&:focus-visible {
		color: $link-color;
	}
	&:active {
		color: $link-color;
	}
	&:visited {
		color: $link-color-visited;
	}

	&.no-visited {
		&:visited {
			color: $link-color;
		}
	}
}

ul ul {
	margin-bottom: 0;
}

textarea.form-control {
	min-height: 10rem;
	border-radius: 0.5rem 0.5rem 0.25rem 0.5rem;
}

input:not([type='checkbox']):not([type='radio']):not(.form-control-plaintext),
.form-select {
	&:not(.is-invalid) {
		border-color: $black-40;
	}
	border-width: 1px;
	border-style: solid;
	appearance: none;
	border-radius: $border-radius;
	
	&:not(select) {
		padding: 0.65rem 0.85rem;
	}

	&.sm {
		padding: 0.2rem 0.65rem;
	}

	&.changed {
		border-color: $yellow-secondary;
		background-color: $yellow-complement;
	}
}
select.form-select {
	padding-left: 0.85rem;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

// input:disabled {
// 	background-color: transparent !important;
// 	border-color: transparent !important;
// 	color: $black-70;
// }

.form-control:disabled,
.form-select:disabled,
.form-control[readonly] {
    background-color: $black-20;
    opacity: 1;

    &.changed {
        background-color: $yellow-complement;
		pointer-events: none;
    }
}

.table > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
}
table {
    th {
        font-family: $font-family;
        font-weight: normal;

        &.semibold {
            font-weight: $font-weight-semibold;
        }
    }
}
p {
	font-family: $font-family;
	font-weight: normal;

	&.semibold {
		font-weight: $font-weight-semibold;
	}
}
