.side-menu {
	position: sticky;
	top: 3rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&--wrapper {
		min-width: 80%;

		p {
			font-weight: 600;
			font-size: 1.125rem;
			margin: 0 0 0.5rem 0.5rem;
		}
	}

	&--buttons {
		display: flex;
		flex-direction: column;

		.btn {
			display: block;
			border: none;
			border-left: 2px solid #bcbcbc;
			border-radius: 0;
			color: #667085;
			padding: 0.5rem 1rem;
			text-align: left;

			&.active {
				border-color: #4075c8;
				background: #f5faff;
				color: #3669ba;
			}

			&:focus,
			&:active {
				box-shadow: none;
			}
		}
	}
}
